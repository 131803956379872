import { colors } from "@boxt/design-system";

import { Products } from "@Collections/products";

import type { ImageFolder, UiContent } from "./types";

export const getDefaultUi = (imageFolder: ImageFolder, productType: Products): UiContent => ({
  background_blur: "4.2579px",
  background_color: colors.slate.normal,
  background_image_desktop: `@s3/customer_next_assets/fallback_images/${imageFolder}/background-hero-lg.png`,
  background_image_alt: "Hero image",
  background_image_mobile: `@s3/customer_next_assets/fallback_images/${imageFolder}/background-hero-sm.png`,
  background_image_quality: 87,
  background_image_tablet: `@s3/customer_next_assets/fallback_images/${imageFolder}/background-hero-md.png`,
  background_opacity: "0.8",
  benefits_color: colors.white,
  benefits_tick_color: productType === Products.BoilerRental ? colors.acidGreen.normal : colors.white,
  cta_color: null,
  heading_theme: "dark",
  heading_theme_strong: productType === Products.BoilerRental ? "acidGreen" : "dark",
  van_image_quality: 100,
  error_bubble_bg_color: colors.coral.normal,
  error_bubble_text_theme: "dark",
  ...(productType === Products.BoilerRental
    ? { left_image_overlay: `@s3/customer_next_assets/fallback_images/${imageFolder}/back-to-life.png` }
    : { left_image_overlay: "" }),
});
