import { type HTMLAttributes } from "react";
import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import type { IStyledComponent } from "styled-components";
import { Accordion, Button, Grid, H3, Paragraph, Tabs as TabsComponents } from "@boxt/design-system";

import CONTACT_DETAILS from "@Collections/contactDetails";
import { actions, category, ReactGAEvent } from "@Lib/googleAnalytics";
import VisibilitySensor from "@Hoc/VisibilitySensor";

import { Row } from "./styles";

const { TabPanel, TabsContainer, Tabs } = TabsComponents;

export type Props = {
  wrapper: IStyledComponent<"web", HTMLAttributes<HTMLDivElement>>;
  i18nNamespace: string;
  gaCategory?: string;
  id?: string;
};

const Faq = ({ wrapper: Wrapper, i18nNamespace, gaCategory = category.landing, id }: Props) => {
  const { t } = useTranslation(i18nNamespace);
  const options: Array<Record<string, string>> = t("faq.categories", {}, { returnObjects: true });

  const getQuestions = (option: string): Array<Record<string, string>> =>
    t(`faq.question-and-answer.${option}.questions`, {}, { returnObjects: true });

  const handleClick = (event) => {
    event.stopPropagation();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleAnalyticEvent = (label: string) => {
    ReactGAEvent({
      category: gaCategory,
      action: actions.faqTabs,
      label,
    });
  };

  return (
    <Wrapper id={id}>
      <Grid.Row as={Row}>
        <Grid.Col>
          <H3 top={{ sm: 7, md: 9 }} bottom={{ sm: 5, md: 7 }} align="center">
            {t("faq.title")}
          </H3>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row as={Row}>
        <Grid.Col lg={{ span: 12, offset: 2 }}>
          <TabsContainer panelPadding={{ top: { sm: 4, md: 6 }, bottom: 9 }}>
            <Tabs top={2} options={options.map(({ text }) => text)} boxtTheme="slate" align="center" />
            {options.map(({ key: optionKey }) => (
              <TabPanel key={optionKey}>
                <VisibilitySensor onVisible={() => handleAnalyticEvent(optionKey)} />
                {getQuestions(optionKey).map(({ key, text }) => (
                  <Accordion top={{ sm: 2, md: 0 }} bottom={{ sm: 2, md: 0 }} key={key} title={text}>
                    <Trans
                      i18nKey={`${i18nNamespace}:faq.question-and-answer.${optionKey}.${key}`}
                      components={{
                        boilerRepair: (
                          <Button
                            boxtTheme="coral"
                            skin="link"
                            href="/boilers/boiler-repair"
                            target="_blank"
                            rel="noopener noreferrer"
                          />
                        ),
                        contactUs: (
                          <Button
                            boxtTheme="coral"
                            skin="link"
                            href="/contact-us"
                            target="_blank"
                            rel="noopener noreferrer"
                          />
                        ),
                        p: <Paragraph bottom={5} boxtTheme="slate" align="left" />,
                        ul: <ul />,
                        li: <li />,
                        homeCover: (
                          <Button
                            boxtTheme="coral"
                            skin="link"
                            href="/home-cover"
                            target="_blank"
                            rel="noopener noreferrer"
                          />
                        ),
                        number: (
                          <Button
                            boxtTheme="coral"
                            skin="link"
                            href={`tel://${CONTACT_DETAILS.PHONE.replace(/\s/g, "")}`}
                          />
                        ),
                        questionnaire: <Button boxtTheme="coral" skin="link" onClick={handleClick} />,
                        email: (
                          <Button boxtTheme="coral" skin="link" href={`mailto://${CONTACT_DETAILS.CUSTOMER_SERVICE}`}>
                            {CONTACT_DETAILS.CUSTOMER_SERVICE}
                          </Button>
                        ),
                        strong: <strong />,
                      }}
                    />
                  </Accordion>
                ))}
              </TabPanel>
            ))}
          </TabsContainer>
        </Grid.Col>
      </Grid.Row>
    </Wrapper>
  );
};

export default Faq;
