"use client";

import styled from "styled-components";
import { colors, includeSpacing } from "@boxt/design-system";

export const TrustPilotReviewsContainer = styled.div`
  width: 100vw;
  height: 100%;
  position: relative;
  overflow: hidden;
  color: ${colors.slate.normal};
  background: ${colors.white};
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
`;

export const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${includeSpacing({
    mb: 1,
    mt: { sm: 4, md: 5 },
  })}
`;

export const WidgetWrapper = styled.div`
  ${includeSpacing({
    mh: 2,
  })}
`;
