"use client";

import styled from "styled-components";
import tinyColor from "tinycolor2";
import { breakpoint, colors, includeSpacing, rem } from "@boxt/design-system";

const radius = rem(10);

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TableGrid = styled.div`
  ${breakpoint("md")} {
    padding: 0 ${rem(32)} 0 ${rem(32)};
  }
  padding: unset;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 70% 30%;
  ${breakpoint("md")} {
    grid-template-columns: 1fr 1fr;
  }
  grid-gap: ${rem(3)};
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const Cell = styled.span`
  display: flex;
  align-items: center;
  min-height: ${rem(35)};
  background: ${tinyColor(colors.slate.lighter).setAlpha(0.15).toRgbString()};
  text-align: center;
  overflow: hidden;
  &:first-child {
    border-radius: unset;
    background: ${tinyColor(colors.slate.lighter).setAlpha(0.4).toRgbString()};
    ${breakpoint("md")} {
      border-radius: ${radius} 0 0 0;
    }
  }
  &:nth-child(2) {
    border-radius: unset;
    background: ${tinyColor(colors.slate.lighter).setAlpha(0.4).toRgbString()};
    ${breakpoint("md")} {
      border-radius: 0 ${radius} 0 0;
    }
  }
  p {
    ${includeSpacing({
      ml: { sm: 2 },
    })}
  }
`;

export const Header = styled(Cell)`
  min-height: ${rem(72)};
  p {
    ${includeSpacing({
      ml: { sm: 2 },
    })}
  }
`;

export const More = styled(Header)`
  cursor: pointer;
  justify-content: center;
  grid-column: 1 / 3;
  border-radius: unset;
  ${breakpoint("md")} {
    border-radius: 0 0 ${radius} ${radius};
  }
`;

export const ArrowWrapper = styled.div`
  ${includeSpacing({
    mt: { sm: 1 },
    mr: { sm: 1 },
  })}
`;
