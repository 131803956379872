import { useEffect, useRef, useState } from "react";
import type TYouTube from "react-youtube";
import { type YouTubeProps } from "react-youtube";

import Image from "@Components/Image";
import { ReactComponent as PlayIcon } from "@Images/icons/play-simple.svg";

import { Container, Cover, IconWrapper, PlayButton, Player, Wrapper } from "./styles";

export type Props = {
  id: string;
  cover?: string;
  options?: YouTubeProps["opts"];
  onPlay?: () => void;
  isOnScreen?: boolean;
};

const Youtube = ({ id, cover, options = {}, onPlay, isOnScreen = true }: Props) => {
  const [isPlaying, setIsPlaying] = useState(options?.playerVars?.autoplay === 1 || false);
  const ref = useRef<TYouTube>(null);
  const defaultOptions: YouTubeProps["opts"] = {
    playerVars: {
      rel: 0,
    },
    ...options,
  };
  useEffect(() => {
    if (!isOnScreen) {
      setIsPlaying(false);
      ref.current?.internalPlayer.pauseVideo();
    }
  }, [isOnScreen, ref]);

  const handlePlayClick = () => {
    ref.current?.internalPlayer.playVideo();
    setIsPlaying(true);
    onPlay?.();
  };

  return (
    <Container>
      <Player $isPlaying={isPlaying} videoId={id} opts={defaultOptions} ref={ref} />
      {!isPlaying && cover && (
        <>
          <Cover data-testid="cover" $isPlaying={isPlaying}>
            <Image src={cover} alt="cover for videos" layout="fill" objectFit="cover" />
          </Cover>
          <PlayButton data-testid="play" $isPlaying={isPlaying} onClick={handlePlayClick}>
            <Wrapper>
              <IconWrapper>
                <PlayIcon />
              </IconWrapper>
            </Wrapper>
          </PlayButton>
        </>
      )}
    </Container>
  );
};

export default Youtube;
