import useTranslation from "next-translate/useTranslation";
import { Button, Grid, H4, H5, Paragraph, Spacing } from "@boxt/design-system";

import YOUTUBE from "@Collections/youtubeDetails";
import { actions, category, ReactGAEvent } from "@Lib/googleAnalytics";
import Slider from "@Components/pages/common/Slider";
import Youtube from "@Components/Youtube";

import { Container, ContentWrapper, DotsStyle, FlexCol, Slide, SliderCol, Title, VideoWrapper } from "./styles";

const FixIt = () => {
  const { t } = useTranslation("repair/index");
  const slides: Record<string, string>[] = t("fix-it.slides", {}, { returnObjects: true });

  return (
    <Container data-testid="fix-it">
      <Grid.Row>
        <Grid.Col as={Title}>
          <H4>{t("fix-it.header")}</H4>
          <Spacing ml={1} mr={1}>
            <Paragraph align="center" top={5}>
              {t("fix-it.title")}
            </Paragraph>
          </Spacing>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col as={SliderCol}>
          <Slider controllerStyle={DotsStyle}>
            {slides.map(({ id, title, text, cover }, i) => (
              <Slide className={`keen-slider__slide number-slide${i}`} key={`fix-it-${id}`}>
                <VideoWrapper>
                  <Youtube
                    id={id}
                    cover={cover}
                    onPlay={() => {
                      ReactGAEvent({
                        category: category.landing,
                        action: actions.videoPlay,
                        label: title,
                      });
                    }}
                  />
                </VideoWrapper>
                <ContentWrapper>
                  <H5 top={5} bottom={3}>
                    {title}
                  </H5>
                  <Paragraph boxtTheme="slate" bottom={6} align="center">
                    {text}
                  </Paragraph>
                </ContentWrapper>
              </Slide>
            ))}
          </Slider>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col as={FlexCol}>
          <Paragraph align="center" boxtTheme="slate">
            {t("fix-it.more-info")}
          </Paragraph>
          <Spacing mb={{ sm: 3, md: 5 }} mt={3}>
            <Button
              skin="secondary"
              boxtTheme="jade"
              href={YOUTUBE.BOXT_PLAYLIST}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("fix-it.more-button")}
            </Button>
          </Spacing>
        </Grid.Col>
      </Grid.Row>
    </Container>
  );
};

export default FixIt;
