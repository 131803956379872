"use client";

import styled, { css } from "styled-components";
import { colors, includeSpacing, Paragraph, rem } from "@boxt/design-system";

const radius = rem(10);

export const FlexTemplate = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  border-bottom: 1px solid ${colors.platinum.light};
  width: 100vw;
  height: 100%;
  position: relative;
  overflow: hidden;
  color: ${colors.slate.normal};
  background: ${colors.white};
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
`;

export const BackGroundColor = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: ${colors.slate.lightest};
`;

export const Title = styled(FlexTemplate)`
  ${includeSpacing({
    mb: { sm: 4, md: 5 },
    mt: { sm: 3, md: 4 },
  })}
`;

export const SlideWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: ${radius};
`;

export const ButtonCol = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${includeSpacing({
    mb: { sm: 4, md: 5 },
    mt: { lg: 5, sm: 0 },
  })}
`;

export const DotsStyle = css`
  ${includeSpacing({
    mt: 3,
    mb: {
      sm: 4,
      md: 3,
    },
  })}
`;

export const StyledParagraph = styled(Paragraph)`
  color: ${colors.white};
  text-align: center;
`;
