import { useEffect, useRef } from "react";
import getConfig from "next/config";
import Script from "next/script";

import { TrustpilotWidgetTemplateIds } from "@Components/pages/common/Trustpilot/constants";

import { Container, StyledSpacing, TrustpilotFrame } from "./styles";
import type { WidgetTheme } from "./types";

const { publicRuntimeConfig } = getConfig();

export type Props = {
  className?: string;
  tags?: string;
  templateId?: string;
  theme?: WidgetTheme;
  stars?: string;
};

const Trustpilot = ({
  className = "",
  tags = "",
  templateId = TrustpilotWidgetTemplateIds.averageRating,
  theme = "light",
  stars = "",
}: Props) => {
  const trustBoxRef = useRef<HTMLDivElement>(null);

  useEffect(() => window?.Trustpilot?.loadFromElement(trustBoxRef.current, true), []);

  return (
    <>
      <Script
        strategy="afterInteractive"
        type="text/javascript"
        src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        id="trustpilot-widget"
      />
      <Container className={className} id="trustpilot" data-testid="trustpilot-widget">
        <StyledSpacing $averageRatingWidget={templateId === TrustpilotWidgetTemplateIds.averageRating}>
          <TrustpilotFrame
            ref={trustBoxRef}
            className="trustpilot-widget"
            data-businessunit-id={publicRuntimeConfig.TRUSTPILOT_BUSINESS_ID}
            data-locale="en-GB"
            data-style-height="100%"
            data-style-width="100%"
            data-template-id={templateId}
            data-theme={theme}
            data-stars={stars}
            data-tags={tags}
          >
            <a href="https://uk.trustpilot.com/review/boxt.co.uk" target="_blank" rel="noreferrer noopener">
              Trustpilot
            </a>
          </TrustpilotFrame>
        </StyledSpacing>
      </Container>
    </>
  );
};

export default Trustpilot;
