import useTranslation from "next-translate/useTranslation";
import { Button, type ButtonProps } from "@boxt/design-system";

import { ReactComponent as Arrow } from "@Images/icons/top-arrow.svg";

export type Props = {
  titleKey?: string;
  position?: number;
  i18nNamespace?: string;
  titleText?: string;
  buttonSize?: ButtonProps["size"];
} & ButtonProps;

const ScrollToButton = ({
  titleKey = "ready-button",
  onClick,
  position = 0,
  i18nNamespace,
  boxtTheme = "jade",
  skin = "secondary",
  buttonSize = "large",
  titleText,
  icon = {
    svg: <Arrow />,
    afix: "right",
    size: "small",
  },
  ...rest
}: Props) => {
  const { t } = useTranslation(i18nNamespace);
  const handleClick = (event) => {
    event.stopPropagation();
    window.scrollTo({
      top: position,
      behavior: "smooth",
    });
    onClick?.(event);
  };
  return (
    <Button
      data-testid="button"
      size={buttonSize}
      onClick={handleClick}
      boxtTheme={boxtTheme}
      skin={skin}
      icon={{
        svg: icon.svg,
        afix: icon.afix,
        size: icon.size,
      }}
      {...rest}
    >
      {titleText ? titleText : t(titleKey)}
    </Button>
  );
};

export default ScrollToButton;
