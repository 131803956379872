import type { InputHTMLAttributes } from "react";
import type { Country, FeatureProps } from "react-phone-number-input";
import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import { useField } from "formik";
import { kebabCase } from "lodash-es";

import PhoneInput from "../PhoneInput";

export type Props = {
  hasHelperText?: boolean;
  hasPlaceholder?: boolean;
  i18nNamespace: string;
  testId?: string;
  light?: boolean;
  defaultCountry?: Country;
  className?: string;
} & FeatureProps<InputHTMLAttributes<HTMLInputElement>>;

const FormikPhoneInput = ({
  hasPlaceholder,
  hasHelperText,
  i18nNamespace,
  light,
  name,
  testId,
  defaultCountry = "GB",
  className,
  ...rest
}: Props) => {
  const [field, meta, helpers] = useField(name as string);
  const { setValue } = helpers;
  const { error, touched, value } = meta;

  const { t } = useTranslation(i18nNamespace);

  const tPrefix = `form.${kebabCase(name)}`;
  const isInvalid = error && touched;

  return (
    <PhoneInput
      {...field}
      {...rest}
      className={className}
      placeholder={hasPlaceholder ? t(`${tPrefix}.placeholder`) : ""}
      value={value}
      onChange={setValue}
      defaultCountry={defaultCountry}
      id={name}
      {...(Boolean(isInvalid) && { invalidText: t(`form.errors.${error}`) })}
      light={light}
      testId={testId}
      {...(hasHelperText && { helperText: t(`${tPrefix}.help`) })}
      invalid={Boolean(isInvalid)}
      labelText={
        t(`${tPrefix}.label`) ?? (
          <Trans
            i18nKey={`${i18nNamespace}:${tPrefix}.label`}
            components={{
              strong: <strong />,
            }}
          />
        )
      }
    />
  );
};

export default FormikPhoneInput;
