"use client";

import styled from "styled-components";
import tinyColor from "tinycolor2";
import { breakpoint, colors, includeSpacing, rem } from "@boxt/design-system";

export const HeroContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: ${rem(330)};
  z-index: -1;

  ${breakpoint("md")} {
    height: ${rem(382)};
  }

  ${breakpoint("lg")} {
    height: ${rem(642)};
  }
`;

export const HeroGradient = styled.div`
  display: none;

  ${breakpoint("lg")} {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: ${rem(410)};
    width: 100%;
    // using hexcode as cant use 0.1 opacity here as it breaks the gradient.
    background: linear-gradient(0deg, #f5f7f9 0%, ${tinyColor(colors.slate.lighter).setAlpha(0).toRgbString()} 68.53%);
  }
`;

export const SecondaryImage = styled.div`
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  ${includeSpacing({ mt: { sm: 8, md: 8 } })};
`;
