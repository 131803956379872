import { Paragraph, Spacing } from "@boxt/design-system";

import type { BACKGROUND_COLORS } from "@Collections/themeColors";

import type { Card as CardType } from "../../types";

import { Container, HighlightedText, StyledTag, StyledText } from "./styles";

type Props = {
  cardData: CardType;
  isFirst: boolean;
};

const Card = ({ cardData, isFirst }: Props) => {
  const {
    text,
    text_color: textColor,
    highlighted_text: highlightedText,
    highlighted_text_color: highlightColor,
    tag_text: tagText,
    bg_color: bgColor,
  } = cardData;

  return (
    <Container
      mt={{ sm: isFirst ? 0 : 2, lg: 0 }}
      ml={{ sm: 0, lg: isFirst ? 0 : 4 }}
      $bgColor={bgColor?.name as keyof typeof BACKGROUND_COLORS}
    >
      <Spacing ma={3}>
        <Spacing mb={{ sm: 6, lg: 8 }}>
          <Paragraph weight="regular" size="large">
            <StyledText $color={textColor?.name}>{text}</StyledText>{" "}
            <HighlightedText $color={highlightColor?.name}>{highlightedText}</HighlightedText>
          </Paragraph>
        </Spacing>
        <StyledTag>
          <Paragraph weight="regular" boxtTheme="slate">
            {tagText}
          </Paragraph>
        </StyledTag>
      </Spacing>
    </Container>
  );
};

export default Card;
