import { StepWrapper } from "../BulletSteps/styles";
import defaultSteps from "../constants";
import type { EasyStep } from "../types";

import { AdjustSize, CenterText, InnerStepWrapper, MobileBulletStepsWrapper, StyledParagraph, Wrapper } from "./styles";

export type StepTextProps = {
  showMobileBulletIcons?: boolean;
  steps: EasyStep[];
};

const StepsText = ({ showMobileBulletIcons, steps, ...restProps }: StepTextProps) => {
  return (
    <Wrapper {...restProps}>
      {steps.map(({ key, copy, isTranslated, bulletIcon }, index) => (
        <InnerStepWrapper key={`as-easy-text-lg-${key}`}>
          {showMobileBulletIcons && (
            <MobileBulletStepsWrapper data-testid="as-easy-as-text-steps-mobile">
              <StepWrapper data-testid="mobile-bullet-icon">{bulletIcon || defaultSteps[index].SVG}</StepWrapper>
            </MobileBulletStepsWrapper>
          )}
          <CenterText>
            <AdjustSize>
              {isTranslated ? (
                copy
              ) : (
                <StyledParagraph
                  boxtTheme="slate"
                  align={{ sm: "left", md: "center" }}
                  size="large"
                  data-testid="styled-paragraph"
                >
                  {copy}
                </StyledParagraph>
              )}
            </AdjustSize>
          </CenterText>
        </InnerStepWrapper>
      ))}
    </Wrapper>
  );
};

export default StepsText;
