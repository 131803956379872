import type { SyntheticEvent } from "react";

export const scrollTop = (event: SyntheticEvent) => {
  event.stopPropagation();

  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};
