import Trans from "next-translate/Trans";
import useTranslation from "next-translate/useTranslation";
import { Button, Paragraph } from "@boxt/design-system";

import CONTACT_DETAILS from "@Collections/contactDetails";
import { BOXT_CUSTOMER_URLS, REPAIR_URLS } from "@Collections/routes";
import useChat from "@Hooks/useChat";

import { InfoContainer } from "./styles";

export type Props = {
  i18n: {
    key: string;
    namespace: string;
  };
};

const MoreInfo = ({ i18n: { namespace, key } }: Props) => {
  const { t } = useTranslation(namespace);
  const content: Record<string, string>[] = t(`${key}.content`, {}, { returnObjects: true });
  const { openChat } = useChat();

  return (
    <InfoContainer>
      {Object.keys(content).map((k) => (
        <Trans
          key={k}
          i18nKey={`${namespace}:${key}.content.${k}`}
          components={{
            p: <Paragraph bottom={4} align="left" boxtTheme="slate" />,
            s: <strong />,
            number: <Button boxtTheme="coral" skin="link" href={`tel://${CONTACT_DETAILS.PHONE.replace(/\s/g, "")}`} />,
            livechat: (
              <Button
                boxtTheme="coral"
                skin="link"
                onClick={() => openChat()}
                target="_blank"
                rel="noopener noreferrer"
              />
            ),
            landing: (
              <Button
                boxtTheme="coral"
                skin="link"
                href={REPAIR_URLS.LANDING}
                target="_blank"
                rel="noopener noreferrer"
              />
            ),
            fullList: (
              <Button
                boxtTheme="coral"
                skin="link"
                href={`${REPAIR_URLS.LANDING}#prices`}
                target="_blank"
                rel="noopener noreferrer"
              />
            ),
            questionnaire: (
              <Button
                boxtTheme="coral"
                skin="link"
                href={BOXT_CUSTOMER_URLS.BOILER}
                target="_blank"
                rel="noopener noreferrer"
              />
            ),
          }}
        />
      ))}
    </InfoContainer>
  );
};

export default MoreInfo;
