"use client";

import styled from "styled-components";
import { breakpoint, includeSpacing, Paragraph, rem } from "@boxt/design-system";

import { StepWrapper } from "../BulletSteps/styles";

export const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const AdjustSize = styled.div`
  width: 100%;
  flex-direction: column;
  ${includeSpacing({ mb: { sm: 3 } })}
  ${breakpoint("md")} {
    max-width: ${rem(200)};
  }
  ${breakpoint("lg")} {
    max-width: ${rem(350)};
  }
`;

export const CenterText = styled(ContentWrapper)`
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled(ContentWrapper)`
  ${includeSpacing({
    mt: { lg: 4, md: 4, sm: 0 },
    mb: { lg: 3, md: 4, sm: 0 },
  })}
  flex-direction: column;

  ${breakpoint("md")} {
    flex-direction: row;
  }
`;

export const InnerStepWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  &:first-child {
    & ${StepWrapper} {
      &:after {
        top: ${rem(20)};
        height: calc(100% - ${rem(20)});
      }
    }
  }

  &:last-child {
    & ${StepWrapper} {
      &:after {
        height: ${rem(20)};
      }
    }
  }
`;

export const StyledParagraph = styled(Paragraph)`
  word-wrap: break-word;
`;

export const MobileBulletStepsWrapper = styled.div`
  max-width: 25%;
  flex: 0 0 25%;
  width: 100%;

  ${breakpoint("md")} {
    display: none;
  }
`;
