import type { FC } from "react";
import { useState } from "react";
import type { CSSObject } from "styled-components";

import Image from "@Components/Image";
import { Container } from "@Components/pages/common/HTMLParser/ParsedImage/ImageWithSizes/styles";
import type { Alignment } from "@Components/pages/common/HTMLParser/ParsedImage/ParsedImage";

export interface Props {
  src: string;
  alignment: Alignment | undefined;
  width?: number;
  height?: number;
  styleObject?: CSSObject;
  alt?: string;
}

const ImageWithSizes: FC<Props> = ({
  styleObject,
  src,
  alignment,
  width: initialWidth = 0,
  height: initialHeight = 0,
  alt = "",
}) => {
  const [width, setWidth] = useState(initialWidth);
  const [height, setHeight] = useState(initialHeight);

  return (
    <Container $styleObject={styleObject} $alignment={alignment}>
      <Image
        src={src}
        width={width}
        alt={alt}
        height={height}
        imgixParams={{
          auto: "format",
        }}
        onLoadingComplete={({ naturalWidth, naturalHeight }) => {
          const ratio = width ? width / naturalWidth : 1;

          setWidth(() => width || naturalWidth);
          setHeight(() => (height ? height : naturalHeight * ratio));
        }}
      />
    </Container>
  );
};

export default ImageWithSizes;
