"use client";

import styled, { css, keyframes } from "styled-components";
import tinyColor from "tinycolor2";
import { breakpoint, BulletPoints, colors, H4, includeSpacing, Paragraph, rem } from "@boxt/design-system";

import { Shadow } from "@Components/layout";
import { Cards } from "@Components/screener";
import { ReactComponent as LoadingSVG } from "@Images/icons/ellipse.svg";
import { ReactComponent as TickIcon } from "@Images/icons/tick.svg";

export const Wrapper = styled.div`
  background-color: ${colors.slate.lightest};
`;

export const ScreenContainer = styled.div<{ $width?: string }>`
  display: flex;
  justify-content: center;
  text-align: center;
  max-width: ${({ $width }) => ($width ? rem($width) : rem(654))};
  margin: 0 auto;
  min-height: none;
`;

export const ButtonContainer = styled.div`
  margin: 0 auto;
  max-width: ${rem(343)};
`;

export const NeedRepairWrapper = styled.div`
  padding: ${rem(24)} 0;
`;

const iconRotate = keyframes`
  0% {
    transform-origin: center;
    transform: rotate(0deg);
  }
  100% {
    transform-origin: center;
    transform: rotate(360deg);
  }
`;

export const LoadingIcon = styled(LoadingSVG)`
  animation: ${iconRotate} 1s linear infinite;
`;

export const StyledCards = styled(Cards)`
  h4 {
    ${breakpoint("lg")} {
      margin: 0 2.5rem;
    }
  }
`;

export const GasWarningContainer = styled.div`
  background-color: ${tinyColor(colors.coral.lighter).setAlpha(0.1).toRgbString()};
  border-radius: ${rem(12)};
  padding: 1.5rem 1.5rem ${rem(9)};
  text-align: left;
`;

export const IssueOverviewContainer = styled.div`
  background-color: ${colors.white};
  border-radius: ${rem(12)};
  padding: 1.5rem 1.5rem ${rem(9)};
  text-align: left;
  word-break: break-word;
`;

export const StyledParagraph = styled(Paragraph)`
  ${includeSpacing({ mb: { sm: 3, md: 4 } })};
`;

export const StyledBulletPoints = styled(BulletPoints)<{ $isUrgent?: boolean }>`
  span {
    color: ${colors.slate.normal};
  }

  svg {
    rect {
      fill: ${({ $isUrgent }) => ($isUrgent ? colors.error : colors.jade.normal)};
    }
  }
`;

export const StyledList = styled.div`
  ul {
    list-style-position: inside;
  }
`;

export const CenteredCol = styled.div`
  display: flex;
  justify-content: center;
  ${includeSpacing({ mt: 7 })};
`;

export const IssueReportedContainer = styled.div`
  ${includeSpacing({ mb: 5 })};
`;

export const StyledH4 = styled(H4)`
  text-transform: uppercase;
`;

export const StyledTickIcon = styled(TickIcon)`
  ${includeSpacing({ mt: 2, mb: { sm: 1, md: 0 } })};
`;

export const PrePopulatedReferenceLabel = styled(Paragraph)`
  ${includeSpacing({ mt: 2, mh: { sm: 2, md: 0 } })}
`;

export const WrapperStyles = css`
  padding-bottom: ${rem(24)};

  ${breakpoint("lg")} {
    padding-bottom: ${rem(32)};
  }
`;

export const Section = styled(Shadow)<{ $isTopCornersHasBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  border-radius: ${({ $isTopCornersHasBorder }) => ($isTopCornersHasBorder ? `${rem(12)} ${rem(12)} 0 0` : "unset")};
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  ${breakpoint("lg")} {
    margin-left: unset;
    margin-right: unset;
    border-radius: ${rem(12)};
  }
  &:nth-child(2) {
    border-radius: ${rem(12)} ${rem(12)} 0 0;
    ${breakpoint("lg")} {
      border-radius: ${rem(12)};
    }
  }
`;
