"use client";

import styled from "styled-components";
import { colors, includeSpacing, Paragraph, rem } from "@boxt/design-system";

export const Container = styled.div`
  width: 100vw;
  height: 100%;
  position: relative;
  overflow: hidden;
  color: ${colors.slate.normal};
  background-color: ${colors.white};
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
`;

export const BackGroundColor = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: ${colors.slate.lightest};
`;

export const Col = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;
export const ButtonCol = styled(Col)`
  ${includeSpacing({
    mt: 4,
  })}
`;
export const linkCol = styled(Col)`
  ${includeSpacing({
    mt: 2,
    mb: { sm: 4, md: 5 },
  })}
`;

export const TableCol = styled.div`
  ${includeSpacing({
    mt: 3,
  })}
`;

export const Header = styled.div`
  ${includeSpacing({
    mh: 2,
  })}
`;

export const PartsWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: ${rem(940)};
  ${includeSpacing({ mh: { sm: 2, md: 0 } })}
`;

export const SvgWrapper = styled.div`
  width: ${rem(32)};
  align-self: center;
  flex: 1 0 auto;
  color: ${colors.slate.light};
  ${includeSpacing({ mh: 2 })};
`;

export const PartsContent = styled(Paragraph)`
  padding-right: ${rem(20)};
`;
