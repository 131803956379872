import { Button, Grid, H3, Spacing } from "@boxt/design-system";

import Card from "./components/Card";
import { CardWrapper, Container } from "./styles";
import type { ButterWaysToPayFields } from "./types";

export type Props = {
  fields: ButterWaysToPayFields;
};

const WaysToPay = ({ fields }: Props) => {
  const { bg_color, title, cards, cta_text, cta_link, cta_theme, cta_skin, id } = fields;

  return (
    <Container $bgColor={bg_color?.name} id={id}>
      <Spacing mv={{ md: 4, lg: 6 }}>
        <Grid>
          <Grid.Row>
            <Grid.Col lg={{ span: 12, offset: 2 }}>
              <H3>{title}</H3>
              <CardWrapper mt={{ md: 3 }} mb={4}>
                {cards.map((card, index) => (
                  <Card cardData={card} isFirst={index === 0} key={card.text} />
                ))}
              </CardWrapper>
              {cta_text && (
                <Button href={cta_link} boxtTheme={cta_theme?.theme} skin={cta_skin || "primary"}>
                  {cta_text}
                </Button>
              )}
            </Grid.Col>
          </Grid.Row>
        </Grid>
      </Spacing>
    </Container>
  );
};

export default WaysToPay;
