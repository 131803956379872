import { useMemo } from "react";
import type { EventArgs } from "react-ga";
import useTranslation from "next-translate/useTranslation";
import type { KeenSliderOptions } from "keen-slider/react";
import { Grid, H3 } from "@boxt/design-system";

import { ReactGAEvent } from "@Lib/googleAnalytics";
import { getColValues, getKeenSliderOptions, getMergedUi } from "@Components/pages/common/LandingSliderWithCTA/helpers";
import SliderItem from "@Components/pages/common/LandingSliderWithCTA/SliderItem/SliderItem";
import {
  BackGroundColor,
  ButtonCol,
  Container,
  DotsStyle,
  SlideWrapper,
  Title,
} from "@Components/pages/common/LandingSliderWithCTA/styles";
import type {
  ButterSliderWithCTAFields,
  UIContent,
  UISlide,
} from "@Components/pages/common/LandingSliderWithCTA/types";
import ScrollToButton from "@Components/pages/common/ScrollToButton";
import Slider from "@Components/pages/common/Slider";

type Props = {
  content: ButterSliderWithCTAFields;
  i18nNamespace: string;
  keyName: string;
  gaScrollEvent: EventArgs;
};

const LandingSliderWithCTA = ({ content, i18nNamespace, keyName, gaScrollEvent }: Props) => {
  const { t } = useTranslation(i18nNamespace);
  const { header, slides, ctaTheme, ctaText }: UIContent = getMergedUi(t, content, i18nNamespace, keyName);
  const { colSpan, colOffset } = useMemo(() => getColValues(slides?.length), [slides]);
  const sliderConfig: KeenSliderOptions = useMemo(() => getKeenSliderOptions(slides), [slides]);

  return (
    <Container id={content.id}>
      <BackGroundColor>
        <Grid.Row>
          <Grid.Col as={Title}>
            <H3>{header}</H3>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col lg={{ span: colSpan, offset: colOffset }}>
            <Slider alwaysShowDots={slides.length > 3} controllerStyle={DotsStyle} sliderOptions={sliderConfig}>
              {slides.map((slide: UISlide, i) => (
                <SlideWrapper className={`keen-slider__slide number-slide${i}`} key={`${keyName}-${i}`}>
                  <SliderItem {...slide} i18nNamespace={i18nNamespace} />
                </SlideWrapper>
              ))}
            </Slider>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col as={ButtonCol}>
            <ScrollToButton
              i18nNamespace={i18nNamespace}
              boxtTheme={ctaTheme}
              skin="primary"
              titleText={ctaText}
              onClick={() => ReactGAEvent(gaScrollEvent)}
            />
          </Grid.Col>
        </Grid.Row>
      </BackGroundColor>
    </Container>
  );
};

export default LandingSliderWithCTA;
